.flex-wrap-grid {
    @include flexify($wrap: wrap, $align: center);
    margin: 0 -#{$spacing} -#{$spacing} 0;

    > * {
	margin: 0 $spacing $spacing 0;
    }

    &.three-col {
	> * {
	    width: calc(33.33% - #{$spacing});
	}
    }

    &.two-col {
	> * {
	    width: calc(50% - #{$spacing});
	}
    }

    &.four-col {
	> * {
	    width: calc(25% - #{$spacing});
	}
    }

    &.align-start {
	align-items: flex-start;
    }
}


.flex-wrap-var-col-size-grid {
    @include flexify($wrap: wrap, $align: center);
    margin: 0 -#{$spacing} -#{$spacing} 0;

    > * {
	margin: 0 $spacing $spacing 0;
	min-width: 320px;
    }

    > .col-6 {
	width: calc(100% - #{$spacing});
    }

    > .col-4 {
	width: calc(66.66666% - #{$spacing});
    }

    > .col-3 {
	width: calc(50% - #{$spacing});
    }

    > .col-2 {
	width: calc(33.33333% - #{$spacing});
    }

    &.reverse {
	flex-direction: row-reverse;
    }

    &.aligntop {
	justify-content: space-between;
    }
}
