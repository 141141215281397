// https://jeeves.local/colour/005FA8/triadic/ryb/

// greys
$black: #000;
$white: #fff;
$grey: darken($white, 50%);
$dark-grey: darken($white, 75%);
$light-grey: darken($white, 25%);
$lighter-grey: darken($white, 10%);
$lightest-grey: darken($white, 5%);

/* main colours */
$sapphire-blue: #005FA8;
$brick-red: #D03759;
$sunglow: #FFCC2F;
$amber: #FF9300;

/* tints and shades (light to dark) */
$sapphire-blue-tint-1: #BFD7E9;
$sapphire-blue-tint-2: #7FAFD3;
$sapphire-blue-tint-3: #3F87BD;
$sapphire-blue-shade-1: #00477E;
$sapphire-blue-shade-2: #002F54;
$sapphire-blue-shade-3: #00172A;
$brick-red-tint-1: #F3CDD5;
$brick-red-tint-2: #E79BAC;
$brick-red-tint-3: #DB6982;
$brick-red-shade-1: #9C2942;
$brick-red-shade-2: #681B2C;
$brick-red-shade-3: #340D16;
$sunglow-tint-1: #FFF2CB;
$sunglow-tint-2: #FFE597;
$sunglow-tint-3: #FFD863;
$sunglow-shade-1: #BF9923;
$sunglow-shade-2: #7F6617;
$sunglow-shade-3: #3F330B;


// colour set
$primary-colour: $sapphire-blue;
$primary-dark: $sapphire-blue-shade-1;
$primary-darker: $sapphire-blue-shade-2;
$primary-darkest: $sapphire-blue-shade-3;
$primary-light: $sapphire-blue-tint-1;
$primary-lighter: $sapphire-blue-tint-2;

$secondary-colour: $brick-red-shade-2;
$secondary-dark: $brick-red-shade-3;
$secondary-light: $brick-red-shade-1;
$secondary-lighter: $brick-red;

$complementary-colour: $amber;

// borders
$border-colour: $lighter-grey;
$highlight-border-colour: $complementary-colour;

// backgrounds
$body-bg-colour: $white;
$header-bg-colour: $primary-colour;
$footer-bg-colour: $primary-darkest;
$card-bg-colour: $lighter-grey;
$latest-news-bg-colour: $lighter-grey;

// overlays and gradients
$overlay-colour: rgba(0,0,0,0.8);
$gradient-colour: rgba(15,30,80,0.3);
$gradient-colour: rgba(15,30,80,0.3);
$gradient-yellow-colour: rgba(255,204,47,0.6);
$transparent-colour: rgba(255,255,255,0);
$caption-bg: rgba(0,0,0,0.6);

// typography
$text-colour: $dark-grey;
$heading-colour: $black;
$link-colour: $complementary-colour;
$link-hover-colour: darken($link-colour, 25%);
$link-active-colour: $primary-colour;
$footer-text-low: $primary-light;
$caption-colour: $white;

// forms
$focus-colour: blue;
$error-colour: red;

// messages
$debug-colour: #B1B6FF;
$info-colour: #A3CEFF;
$success-colour: #90E1CA;
$warning-colour: #FFE0A3;
$error-colour: #FFAEA3;

// icons
$icon-colour: $black;

// menu
$primary-menu-bg: $white;
$primary-sub-menu-bg: $primary-light;

$primary-menu-link-colour: $text-colour;
$primary-menu-active-link-colour: $complementary-colour;

$primary-menu-hover-bg: $primary-lighter;
$primary-menu-hover-colour: $primary-menu-link-colour;

$primary-sub-menu-link-colour: $primary-menu-link-colour;
$primary-sub-menu-hover-bg: $primary-lighter;
$primary-sub-menu-active-colour: $primary-menu-active-link-colour;

$primary-mobile-sub-menu-bg: $white;
