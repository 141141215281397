.gallery, .thumb-grid {
    //@extend .flex-wrap-grid;
    display: grid;
    grid-gap: $spacing-sm;
    grid-template-columns: repeat(auto-fill, minmax(min(15rem, 100%), 1fr));

    figure {
	transition: all 0.5s ease-in-out;

	a, img {
	    display: block;
	    margin: 0;
	    transition: transform 0.5s ease-in-out;
	    border-radius: $border-radius;
	}

	&:hover {
	    @include boxshadow();
	    img {
		transform: scale(1.5);
	    }
	}
    }
}

body.possesed {
    overflow: hidden;
}

#overlord {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    z-index: 0;

    background-color: $overlay-colour;

    @include flexify($align: center, $justify: center);
    @include prefixer(( transition: opacity 1s ease ), webkit moz ms o);
    display: none;


    &.now-showing {
	display: flex;
	opacity: 1;
	z-index: 1000;
    }

    #gallery-inner {

	@include flexify($align: center, $justify: center);

	> a {
	    font-size: $scale3;
	    color: $white;
	    font-family: monospace;
	    text-decoration: none;

	    &:hover {
		color: $link-colour;
	    }
	}

	figure {
	    @include prefixer((transition: opacity .5s ease), webkit moz ms o);

	    &.faded {
		opacity: 0;
	    }

	    img {
		//max-width: 90%;
		max-height: 90vh;
	    }

	    figcaption {
		color: $white;

		span {
		    text-align: right;
		    font-size: $scale-1;
		}
	    }
	}
    }


    .close-btn {
	position: absolute;
	top: $spacing;
	right: $spacing;

	font-size: $scale2;
	color: $white;
	cursor: pointer;

	&:hover {
	    color: $link-colour;
	}
    }
}
