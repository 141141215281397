.icon {
    stroke: $icon-colour;
    width: $icon-width;

    &.icon-sm {
	width: $icon-width-sm;
    }

    &.icon-lg {
	width: $icon-width-lg;
    }

    &.icon-xl {
	width: $icon-width-xl;
    }

    &.icon-xxl {
	width: $icon-width-xxl;
    }
}


