/*
Title: Elemetary CSS Framework
Description: A simple CSS framework for my client and personal work, using SASS
Author: Prathamesh Pai
Author URI: http://transducent.com/
Version: 1.5.0
*/

// required modules: edit these if needed
@import 'vars';
@import 'colours';
@import 'reset';
@import 'mixins';
@import 'base';
@import 'formatting';
@import 'images';
@import 'layout';
@import 'nav';
@import 'menus';

// optional modules: disabled them if not required
@import 'icons';
@import 'forms';
@import 'flexgrid';
@import 'offcanvas';
@import 'carousel';
@import 'gallery';
@import 'cards';

// site specific styles: removed or edit as needed
@import 'site';

