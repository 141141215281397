.card {
    background-color: $card-bg-colour;
    padding: $spacing;
    border-radius: $border-radius;
}

ul.cards, ol.cards {
    list-style: none;

    li {
	@extend .card;
	margin: 0 0 $spacing 0;
    }

    &.inline {
	li {
	    @extend .inline-block;
	    margin: $spacing $spacing 0 0;
	}
    }
}
