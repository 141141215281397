// prefixer
// usage:
// @include prefixer((
//	transition: opacity 1s ease,
//	border-radius: 2px
// ), webkit moz ms o);
@mixin prefixer($declarations, $prefixes: ()) {
    @each $property, $value in $declarations {
	@each $prefix in $prefixes {
	    #{'-' + $prefix + '-' + $property}: $value;
	}
	// non-prefixed
	#{$property}: $value;
    }
}

// flexer
@mixin flexify($direction: row, $wrap: nowrap, $justify: flex-start, $align: stretch, $aligncontent: flex-start, $inline: false) {
    @if $inline {
	display: inline-flex;

    } @else {
	display: flex;
    }

    flex-direction: $direction;
    flex-wrap: $wrap;
    justify-content: $justify;
    align-items: $align;
    align-content: $aligncontent;
}


// keyframer
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
	@content;
    }

    @-moz-keyframes #{$name} {
	@content;
    }

    @-ms-keyframes #{$name} {
	@content;
    }

    @-o-keyframes #{$name} {
	@content;
    }

    @keyframes #{$name} {
	@content;
    }
}


@include keyframes(fadein) {
    0% {
	opacity: 0;
    }

    100% {
	opacity: 1;
    }
}


@include keyframes(rotate) {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin boxshadow() {
    box-shadow: 0.25em 0.25em 2em $dark-grey;
}
