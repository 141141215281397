a, a:link, a:visited {
    text-decoration: none;
}

figcaption {
    font-style: italic;
    color: lighten($text-colour, 5%);
}


#lang-menu {
    text-align: right;
    font-size: $scale-1;
    padding: $spacing;
    background-color: darken($primary-colour, 5%);
}




// LAYOUT

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}




// -- main footer
#site-footer {
    background-color: $footer-bg-colour;
    padding: $spacing-lg*4;

    h1, h2, h3, h4 {
	color: $white;
    }

    h5 {
	color: $footer-text-low;
	text-align: center;
    }
}

.adr p {
    line-height: $leading-sm;
}






// -- main header
#site-header {
    padding: 0;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    border-bottom: 0.5em solid $complementary-colour;
		background: linear-gradient($primary-colour, $primary-light);
}

#logo {
    //justify-content: center;
    //text-decoration: none;

    > img {
	display: block;
    }

    > #site-title {
	margin-left: $spacing-sm;
	@media screen and (min-width: 40em) {
	    margin-left: $spacing-lg;
	}

	h2 { 
	    font-size: $scale0; 
	    @media screen and (min-width: 40em) {
		font-size: $scale1;
	    }
	}
	p { 
	    font-size: $scale-1; 
	    @media screen and (min-width: 40em) {
		font-size: $scale0;
	    }
	}

    }
}
#besant-logo {
    width: 150px;
}
#college-logo {
    max-width: 60px;
}
#college-name {
    color: $white;
}
.extra-data {
    color: $primary-light;
}




// -- main content
main {
    flex: 1;

    > section {
	background-color: $light-grey;
	padding: $spacing*4 0;

	&#latest-news {
	    background-color: $latest-news-bg-colour;

	    //h2 {
		//color: $white;
	    //}
	}

	&#upcoming-events {
	    background-color: $primary-colour;

	    h2 {
		color: $white;
	    }
	}
    }
}

#hero {
    border-bottom: 0.5em solid $highlight-border-colour;

    img {
	width: 100%;
	display: block;
    }
}

#content {
    @media (min-width: $breakpoint-2) {
	display: grid;
	grid-template-columns: 1fr 20rem;
	gap: $spacing-xl;
	grid-gap: $spacing-xl;
	margin: $spacing*4 auto;
    }

    &.fullwidth {
	display: block;
    }
}

#sidebar {
    h2, h3 {
	margin-top: $spacing-lg;
	background-color: $primary-menu-hover-bg;
	color: $white;
	padding: $spacing-sm;
	border-bottom: 4px solid $highlight-border-colour;
    }
}

#sidebar .container {
    padding: 0;
    width: auto;
}

#blog-posts ul {
    margin-bottom: $spacing-xl;
}

ul.search-results li {
    margin: 0 0 $spacing $spacing;
}




// -- misc formatting

.thumb {
    margin: $spacing $spacing 0 0;
}

.person {
    figure {
	margin: 0 $spacing $spacing 0;
    }
}

#overlay-data {
    display: none;
}
#popup-inner {
    color: $white;
    padding: $spacing;
    margin-top: $spacing*6;
    max-width: 80rem;


    h1, h2, h3, h4, h5, h6 {
	color: $white;
    }
}




// -- home page


figure.highlighted {
    width: 100%;
    position: relative;

    img {
	width: 100%;
	display: block;
    }

    figcaption {
	position: absolute;
	left: 0;
	bottom: 0;
	z-index: 100;
	width: 100%;
	background-color: $gradient-colour;
	padding: $spacing;
	//padding-bottom: $spacing-xl + $spacing;
	transition: all 1s ease-in-out;

	.container {
	    padding: $spacing-sm;
	}

	h2, h3, p {
	    color: $caption-colour;
	    text-shadow: 1px 1px $black;
	    margin: 0;
	}
    }

    &:hover {
	figcaption {
	    //background-color: $gradient-yellow-colour;
	    background-color: $highlight-border-colour;

	    p, h1, h2, h3 {
		//color: $text-colour;
		text-shadow: none;
	    }
	}
    }


    //&::after {
	//display: block;
	//position: absolute;
	//top: 0;
	//left: 0;

	//content: "";
	//background: radial-gradient(circle, $transparent-colour 0%, $gradient-colour 50%);
	//width: 100%;
	//height: 100%;
    //}
}


#main-carousel {
    margin: 0;
    padding: 0;
    border-bottom: 0.5em solid $highlight-border-colour;


    nav.overlay {
	height: auto;
    }
}

#home-highlights {
    background-color: $white;
    padding-bottom: $spacing-xl;

    > .container {
	padding: $spacing-xl;
	z-index: 900;
	position: relative;

	> h2, > p {
	    text-align: center;
	}
    }
}
.highlights {

    > a {
	display: block;
	//background-color: $primary-colour;
	margin: $spacing;
	max-width: 25em;
	position: relative;

	figcaption {
	    border-bottom: 0.5em solid $highlight-border-colour;
	}
    }
}

figure.figfx {
    border-radius: $border-radius;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    max-width: 25em;

    figcaption {
	.fxbg {
	    content: "";
	    display: block;
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    overflow: hidden;
	    background-color: $highlight-border-colour;
	    transform: scaleY(0);
	    transition: all 0.5s ease;
	    z-index: -1;
	}
    }

    &:hover {
	@include boxshadow();

	figcaption {
	    .fxbg {
		transform: scaleY(1);
	    }

	    p, h1, h2, h3 { 
		//color: $text-colour; 
		text-shadow: none;
	    }
	}
    }
}

#home-content {
    background-color: $white;    
}

#affiliations {
    display: flex;
    justify-content: center;
    align-items: baseline;

    figure {
	max-width: 12em;
	margin: 0.5em;

	figcaption { 
	    color: $footer-text-low;
	}
    }
}

#home-contact-form {
    flex: 1;
    input, textarea {
	width: 90%;
    }
}

#alumni-testimonials {
    background-color: $white;

    .container > div {
	align-items: center;	
    }

    .testimonial {
	background-color: $lighter-grey;
	padding: $spacing;
	border-radius: $border-radius;

	> img {
	    display: block;
	    margin: 0 $spacing $spacing 0;
	}

	> div {
	    flex: 1;

	    > p:first-child{
		&::before {
		    content: "\201C";
		}
		&::after {
		    content: "\201D";
		}
	    }

	    > p:last-child {
		font-weight: bold;
	    }
	}
    }
}

.news-items {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spacing;
    grid-gap: $spacing;

    @media screen and (min-width: 40em) {
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(2, 1fr);
    }

    a {
	color: $primary-colour;

	&:hover {
	    color: $complementary-colour;
	}

	@media screen and (min-width: 40em) {
	    &:first-child {
		grid-row: 1;
		grid-column: 1/3;
	    }

	    &:nth-child(4) {
		grid-row: 2;
		grid-column: 3/5;
	    }
	}
    }

    .news-item {
	background-color: $light-grey;	
	display: flex;
	flex-direction: column;
	height: 100%;
	border-radius: $border-radius;
	overflow: hidden;
	transition: all 1s ease-in-out;

	img {
	    width: 100%;
	    display: block;
	    transition: all 1s ease-in-out;
	}

	figcaption {
	    padding: $spacing-sm;
	    background-color: $white;
	    flex: 1;
	    border-top: 0.25em solid $highlight-border-colour;
	    transition: all 1s ease-in-out;

	    .sm {
		transition: all 1s ease-in-out;
	    }
	}

	&:hover {
	    @include boxshadow();

	    img {
		filter: brightness(150%);
	    }
	    figcaption {
		background-color: $highlight-border-colour;
		color: $white;

		.sm { color: $white; }
	    }
	}

    }
}

.upcoming-events li, .news li {
    margin-bottom: $spacing-lg;
}

nav.news-nav {
    margin: $spacing 0;
    padding: $spacing 0;
    border-top: 1px solid $lighter-grey;

    li {
	color: $grey;
	margin-bottom: $spacing;
    }
}

.txtimg {
    @include flexify($wrap: wrap, $align: flex-start);
    margin: 0 -#{$spacing} -#{$spacing} 0;

    &.reverse {
	flex-direction: row-reverse;
    }

    > * {
	margin: 0 $spacing $spacing 0;
	min-width: 320px;
    }

    figure {
	max-width: 20em;

	a {
	    cursor: zoom-in;
	}
    }

    aside {
	flex: 1;
    }
}

.people {
    .person, img { max-width: 15em; }

    &--sm {
	.person, img { max-width: 10em; }
    }

    &--lg {
	.person, img { max-width: 25em; }
    }

    &--horizontal {
	@extend .flex-wrap-grid;
    }

    &--vertical {
	flex-direction: column;
	margin: 0;

	.person {
	    max-width: none;
	    display: flex;
	    flex-wrap: wrap;
	    align-items: flex-start;
	    margin-bottom: $spacing;

	    img {
		margin-right: $spacing;
	    }
	}
    }
}

.hit-count {
    font-family: Courier, monospace;
    font-size: $scale0;
}

#faculty {
    margin: $spacing-lg 0;
}
.faculty {
    figure {
	width: 10em;

	img {
	    width: 100%;
	}
    }
}

// extra for jekyll

ol.sitemap {
    margin: $spacing;

    ul, ol {
	margin: 0 $spacing-sm;
    }
}


.news-item > img {
    width: 15em;
    height: 15em;
    object-fit: cover;
}

.upcoming-events {
    details {
	color: $white;

	summary { 
	    color: $link-colour; 

	    &:hover { color: $link-hover-colour; }
	}
    }

}

#home-downloads {
    background-color: darken($primary-colour, 5%);

    .flex-wrap-var-col-size-grid { align-items: start; }

    .download-block {
	font-size: $scale-1;

	h3 {
	    color: $white;
	    font-size: $scale0;
	    font-weight: normal;
	}
    }
}

.testimonial img { width: 12em; }

#alumni-testimonials .carousel-outer {
    justify-content: center;
}

main {
    ul, ol {
	margin-left: $spacing;

	&.no-bull {
	    margin-left: 0;
	}
    }

    nav ul, nav ol {
	margin-left: 0;
    }
}

nav#blog-posts a, #sidebar ul a {
    color: $primary-colour;

    &:hover, &.active {
	color: $primary-menu-active-link-colour;
    }
}

.toggle-menu {
    position: absolute;
    top: $spacing-sm;
    right: $spacing-sm;
    font-size: $scale-1;
    display: none;

    //@media not (hover: hover) {
	//display: block;
    //}
}

.grid-2c {
    @media (min-width: $breakpoint-2) {
	display: flex;
	align-items: center;
	justify-content: center;

	article {
	    margin-right: $spacing;
	}

	aside img {
	    border-radius: $border-radius;
	}
    }
}

.home-intro aside img {
    max-width: 20em;
}

#home-admissions .grid-2c {
    article {
	background-color: $white;
	padding: $spacing-lg*4;
	max-width: 60ch;
    }

    aside {
	margin-left: -$spacing-lg*2;
	max-width: 28rem;
    }
}

#home-infra {
    background-color: $secondary-dark;

    .grid-2c {
	flex-direction: row-reverse;
    }

    article {
	background-color: $light-grey;
	padding: $spacing-lg*4;
	margin-left: -$spacing-lg*2;

	p {
	    max-width: 60ch;
	}
    }

    aside {
	max-width: 20rem;
    }
}


#home-courses {
    background-color: $white;

    > h2, > p { text-align: center; }
}

.separated-div {
    margin: $spacing-lg 0;
}

[style*="--aspect-ratio"] > :first-child {
    width: 100%;
    max-width: 640px;
}
[style*="--aspect-ratio"] > img {  
    height: auto;
} 
@supports (--custom:property) {
    [style*="--aspect-ratio"] {
	position: relative;
    }
    [style*="--aspect-ratio"]::before {
	content: "";
	display: block;
	padding-bottom: calc(100% / (var(--aspect-ratio)));
    }  
    [style*="--aspect-ratio"] > :first-child {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
    }  
}

.admission-grid {
    flex-direction: row-reverse;

    @media (min-width: 960px) {
	display: flex;
    }

    aside {
	flex: 1 0 640px;
    }
}

form .btn {
		display: inline-block;
}

@keyframes reveal {
		from {
				opacity: 0;
				clip-path: inset(45% 20% 45% 20%);
		}
		to {
				opacity: 1;
				clip-path: inset(0% 0% 0% 0%);
		}
}
@keyframes slideleft {
		from { transform: translateX(-20%); }
		to { transform: translateX(0); }
}
@keyframes slideright {
		from { transform: translateX(20%); }
		to { transform: translateX(0); }
}
@keyframes fadein {
		from { opacity: 0; }
		to  { opacity: 1; }
}
@keyframes scalin {
		from { transform: scale(0.5); }
		to { transform: scale(1); }
}
.reveal {
		animation: reveal linear both;
		animation-timeline: view();
		animation-range: cover 0% cover 50%;
}
.slide-left {
		animation: slideleft linear both;
		animation-timeline: view();
		animation-range: cover 0% cover 40%;
}
.slide-right {
		animation: slideright linear both;
		animation-timeline: view();
		animation-range: cover 0% cover 40%;
}
.scalin {
		animation: scalin linear both;
		animation-timeline: view();
		animation-range: cover 0% cover 40%;
}
.fade-in {
		animation: fadein 1.5s ease-in;
}
@media screen and (max-width: 60em) {
		.slide-left, .slide-right {
				animation: fadein 1.5s ease-in;
		}
}

@media (prefers-reduced-motion: reduce) {
		* {
				animation-duration: 0.01ms !important;
				animation-iteration-count: 1 !important;
				transition-duration: 0.01ms !important;
				scroll-behavior: auto !important;
		}
}
