.container {
    width: 100%;
    max-width: $container-max-width;
    padding: $spacing;
    margin: 0 auto;
}

.screen-reader-only {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}
.hide-completely {
    display: none;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.inline-block {
    display: inline-block;
}

.flexy {
    @include flexify();

    &.wrap {
	flex-wrap: wrap;
    }

    &.align-centre {
	align-items: center;
    }

    &.justify-centre {
	justify-content: center;
    }

    &.column {
	flex-direction: column;
    }

    &.reverse {
	flex-direction: row-reverse;
    }

    &.space-between {
	justify-content: space-between;
    }

    &.space-around {
	justify-content: space-around;
    }

    &.align-start {
	align-items: flex-start;
    }

    &.align-end {
	align-items: flex-end;
    }

    &.justify-start {
	justify-content: flex-start;
    }

    &.justify-end {
	justify-content: flex-end;
    }

    &.middle {
	justify-content: center;
	flex-direction: column;
    }

    > * {
	&.align-start {
	    align-self: flex-start;
	}

	&.align-end {
	    align-self: flex-emd;
	}

	&.align-centre {
	    align-self: center;
	}

	&.flex-one {
	    flex: 1;
	}
    } 
}




#site-header {
    background-color: $header-bg-colour;
}

#site-footer {
    background-color: $footer-bg-colour;
    font-size: $scale-1;
    color: $white;
}
