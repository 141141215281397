* {
    //-moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}
html {
    font-size: 87.5%; // 14px

    @media screen and (min-width: 40em) {
	font-size: 93.75%; // 15px at 640+
    }

    @media screen and (min-width: 60em) {
	font-size: 100%; // 16px at 960+
    }

}
body {
    font-size: $scale0;
    font-family: $font;
    line-height: $leading;

    color: $text-colour;
    background-color: $body-bg-colour;

    min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    color: $heading-colour;
    line-height: $leading-xs;

    + p {
	margin-top: 0;
    }
}
h1 {
    font-size: $scale3;
    margin-bottom: $spacing;
}
h2 {
    font-size: $scale2;
}
h3 {
    font-size: $scale1;
}
//h4 {
    //font-size: $scale0;
//}
//h5 {
    //font-size: $scale-1;
//}
//h6 {
    //font-size: $scale-2;
//}

p {
    margin: $spacing 0;
}

strong, b {
    font-weight: bold;
}

em, i {
    font-style: italic;
}

ul, ol {
    margin: $spacing 0;
}
ol {
    list-style-type: decimal;
}
ul {
    list-style-type: disc;
}

table, th, td {
    border: 1px solid $border-colour;
}
th {
    font-weight: bold;
}
td {
    padding: $spacing-sm;
}

code {
    display: block;
    background-color: $lighter-grey;
    padding: $spacing;
    margin: $spacing 0;
}

details {
    summary {
	cursor: pointer;
    }
}
