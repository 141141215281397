.normal {
    font-weight: normal;
}
.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.txt-align-right {
    text-align: right;
}

.txt-align-left {
    text-align: left;
}

.txt-align-centre {
    text-align: center;
}

.txt-align-justify {
    text-align: justify;
}

.txt-align-vertical-centre {
    vertical-align: middle;
}

.quote {
    border: 3px double $border-colour;
    border-width: 3px 0;
    padding: $spacing;

    article {
	font-style: italic;
    }

    address {
	&.author {
	    &:before {
		content: "\2014";
		display: inline-block;
		margin-right: $spacing-sm;
	    }
	}
    }
}


.meta {
    color: $light-grey;
}

.sm {
    font-size: $scale-1;
}

.lg {
    font-size: $scale1;
}

.uc {
    text-transform: uppercase;
}

.err {
    color: $error-colour;
}

.lc {
    text-transform: lowercase;
}


.message, .toast {
    display: block;
    padding: $spacing;

    @include prefixer(( animation: 3s 1 fadein ), webkit moz ms o);

    &.debug {
	background-color: $debug-colour;
    }

    &.info {
	background-color: $info-colour;
    }

    &.success {
	background-color: $success-colour;
    }

    &.warning {
	background-color: $warning-colour;
    }

    &.error {
	background-color: $error-colour;
    }
}

ul, ol {
    &.indented {
	margin-left: $spacing;
    }

    &.no-bull {
	list-style-type: none;
    }

    &.messages, &.toasts {
	list-style-type: none;
	margin: 0;

	li {
	    @extend .message;
	}
    }

    &.non-field-errors, &.field-errors, &.errors {
	list-style-type: none;
	margin: 0;

	li {
	    color: $error-colour;
	}
    }
}

.disabled {
    opacity: 0.5;

    input, textarea, select, label {
	pointer-events: none;
    }
}

.loading {
    @extend .disabled;
    color: transparent !important;
    min-height: 2em;
    pointer-events: none;
    position: relative;

    &::after {
	@include prefixer(( animation: 1s rotate infinite linear), webkit moz ms o);
	background: transparent;
	border: 2px solid $grey;
	border-radius: 50%;
	border-right-color: transparent;
	border-top-color: transparent;
	content: "";
	display: block;
	height: 2em;
	left: 50%;
	margin-left: -1em;
	margin-top: -1em;
	opacity: 1;
	padding: 0;
	position: absolute;
	top: 50%;
	width: 2em;
	z-index: 1000;
    }
}
