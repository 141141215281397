@mixin input-shadow($shadow-colour) {
    box-shadow: 0 0 $widget-box-shadow-size 0 $shadow-colour;
}
@mixin input-focus {
    @include input-shadow($primary-colour);
}
@mixin input-error {
    border-width: 2px;
    border-color: $error-colour;
}
@mixin help-text {
    display: block;
    font-size: $scale-1;
    color: $grey;
}
form {

    ul {
	li {
	    margin-bottom: $spacing;

	}

	&.errorlist {
	    list-style: none;
	    font-size: $scale-1;
	    li {
		margin: 0;
		color: $error-colour;
	    }
	}
    }

    .has-errors {
	input:not([type="checkbox"]):not([type="radio"]), 
	.checkbox label:before,
	textarea, select {
	    @include input-error;
	}
    }

    .helptext {
	@include help_text;
    }
}
label {
    display: block;

    &.required {
	&:after {
	    content: "*";
	    color: $secondary-colour;
	}
    }
}
input:not([type="checkbox"]):not([type="radio"]), 
textarea, 
select {
    @include prefixer((
	appearance: none
    ), webkit moz ms o);

    background: $white;
    background-image: none;
    box-shadow: none;
    outline: none;

    display: block;
    font-size: $scale0;
    border: 1px solid $border-colour;
    border-radius: $border-radius;
    height: $widget-height;
    width: $widget-width;
    padding: 0 $spacing-sm;

    &:focus {
	@include input-focus;
    }

    &:invalid:focus {
	@include input-error;
    }
}
textarea {
    height: auto;
}
select {
    color: inherit;
    vertical-align: middle;

    &:not([multiple]):not([size]) {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%204%205'%3E%3Cpath%20fill='%23667189'%20d='M2%200L0%202h4zm0%205L0%203h4z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right .35rem center;
	background-size: .4rem .5rem;
	padding-right: $spacing;
    }
}
input[type="checkbox"] {
    display: inline-block;

    + label {
	display: inline-block;
    }
}
label.switch {
    input[type="checkbox"] {
	display: none;

	&:checked + span {
	    background-color: $primary-colour;
	    &::before {
		left: 1.1em;
	    }
	}
    }

    span {
	background-color: $light-grey;
	background-clip: padding-box;
	border-radius: 1em;
	display: inline-block;
	vertical-align: middle;
	line-height: $leading;
	width: 2.2em;
	height: 1.2em;
	position: relative;
	@include prefixer(( transition: background .2s ease ), webkit moz ms o);

	&::before {
	    background-color: $white;
	    border-radius: 50%;
	    content: "";
	    display: block;
	    position: absolute;
	    top: 0.1em;
	    left: 0.1em;
	    height: 1em;
	    width: 1em;
	    @include prefixer(( transition: left .2s ease ), webkit moz ms o);
	}
    }

}
.checkbox {
    input[type="checkbox"] {
	position: absolute;
	left: -9999px;

	+ label {
	    &:after {
		content: none;
	    }
	}

	&:checked {
	    + label {
		&:before {
		    background-color: $primary-colour;
		    border-width: 0;
		}

		&:after {
		    content: "";
		}
	    }
	}

    }

    label {
	position: relative;
	display: inline-block;
	padding-left: 1.7em;
	line-height: 1.5em;

	&:before {
	    content: "";
	    display: inline-block;
	    
	    height: 1.2em;
	    width: 1.2em;

	    border: 1px solid $border-colour;
	    border-radius: $border-radius;

	    position: absolute;
	    top: 3px;
	    left: 0;
	}

	&:after {
	    content: "";
	    color: $white;
	    display: inline-block;

	    height: 0.35em;
	    width: 0.6em;

	    border-left: 2px solid;
	    border-bottom: 2px solid;

	    transform: rotate(-45deg);

	    position: absolute;
	    top: 6px;
	    left: 3px;
	}
    }
}

.field {
    margin: 0 $spacing-sm $spacing-sm 0;

    button {
	margin-top: 1.5em;
    }
}

button {
    &.btn{
	font-size: $scale0;
	height: 2em;
	cursor: pointer;
    }
}

fieldset {
    margin: $spacing 0;
    
    legend {
	display: block;
	width: 100%;
	font-weight: bold;
	font-size: $scale0;
	margin-bottom: $spacing;
	padding-bottom: $spacing-sm;
	border-bottom: 1px dotted $border-colour;
    }
}

.inline-fields {
    @include flexify($wrap: wrap);

    .inline-field {
	margin-right: $spacing;
	
	label {
	    @include help-text;
	}
    }
}
.input-sm input:not([type="checkbox"]):not([type="radio"]), 
.input-sm select {
    width: $widget-width-sm;
}
.input-xs input:not([type="checkbox"]):not([type="radio"]), 
.input-xs select {
    width: $widget-width-xs;
}
