a, a:visited {
    color: $link-colour;

    .icon {
	stroke: $link-colour;
    }

    &:hover {
	color: $link-hover-colour;

	.icon {
	    stroke: $link-hover-colour;
	}
    }

    &.active {
	color: $link-active-colour;
    }

    &.with-inline-svg-icon {
	@include flexify($align: center, $inline: true);
	gap: $spacing-sm;

	//.icon {
	    //margin-right: $spacing-sm;
	//}

	&.vertical {
	    flex-direction: column;

	    .icon {
		margin: 0;
	    }
	}
    }
}

.btn {
    text-decoration: none;
    outline: none;
    border: none;
    line-height: 1;

    // default settings
    padding: $spacing-sm;
    display: inline-block;
    background: transparent;
    border: 1px solid;
    color: $complementary-colour;

    .icon {
	stroke: $complementary-colour;
    }

    &:hover {
	color: darken($complementary-colour, 25%);

	.icon {
	    stroke: darken($complementary-colour, 25%);
	}
    }

    // sizes
    &.sm {
	padding: $spacing-xs;
    }

    &.md {
	padding: $spacing-sm*2;
    }

    &.lg {
	padding: $spacing;
    }

    &.xs {
	padding: $spacing-xxs;
    }


    // colours
    &.primary {
	color: $primary-colour;

	.icon {
	    stroke: $primary-colour;
	}

	&:hover {
	    color: darken($primary-colour, 25%);

	    .icon {
		stroke: darken($primary-colour, 25%);
	    }
	}
    }

    &.secondary {
	color: $secondary-colour;

	.icon {
	    stroke: $secondary-colour;
	}

	&:hover {
	    color: darken($secondary-colour, 25%);

	    .icon {
		stroke: darken($secondary-colour, 25%);
	    }
	}
    }

    &.grey {
	color: $light-grey;

	.icon {
	    stroke: $light-grey;
	}

	&:hover {
	    color: darken($light-grey, 25%);

	    .icon {
		stroke: darken($light-grey, 25%);
	    }
	}
    }

    &.block {
	display: block;
    }

    &.rounded {
	border-radius: $border-radius*2;
    }

    // corners
    .rounded {
	border-radius: $border-radius*2;
    }

    // bg
    &.solid {
	border: none;
	background-color: $complementary-colour;
	color: $white;
	font-weight: bold;
	transition: all 0.5s ease-in-out;
	text-transform: uppercase;

	&:hover {
	    background-color: $secondary-colour;
	    color: lighten($complementary-colour, 25%);
	    @include boxshadow();

	    .icon {
		stroke: lighten($complementary-colour, 25%);
	    }
	}

	.icon {
	    stroke: $white;
	    stroke-width: 2;
	}

	&.primary {
	    background-color: $primary-colour;

	    &:hover {
		color: darken($primary-colour, 25%);

		.icon {
		    stroke: darken($primary-colour, 25%);
		}
	    }
	}

	&.secondary {
	    background-color: $secondary-colour;

	    &:hover {
		color: darken($secondary-colour, 25%);

		.icon {
		    stroke: darken($secondary-colour, 25%);
		}
	    }
	}

	&.grey {
	    background-color: $light-grey;

	    &:hover {
		color: darken($light-grey, 25%);

		.icon {
		    stroke: darken($light-grey, 25%);
		}
	    }
	}
    }

    // disabled
    &.disabled {
	background-color: $lighter-grey;
	color: $light-grey;
	cursor: default;
	border: none;

	.icon {
	    stroke: $light-grey;
	}

	&:hover {
	    border: none;
	    background-color: $lighter-grey;
	    color: $light-grey;

	    .icon {
		stroke: $light-grey;
	    }
	}
    }
}
nav {
    ul {
	margin: 0;
	list-style-type: none;
    }

    &.horizontal {
	ul {
	    @include flexify($wrap: wrap);
	    margin-right: -$spacing;
	    margin-bottom: -$spacing;

	    li {
		margin-right: $spacing;
		margin-bottom: $spacing;
	    }
	}
    }

    &.breadcrumbs {
	ul li {
	    display: inline-block;

	    &:last-child {
		&:after {
		    content: "";
		}
	    }

	    &:after {
		content: $breadcrumb-separator;	
		margin: 0 $spacing-sm;
	    }
	}
    }
}
