// typography
//@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;1,400&display=swap');


$font: "Nunito", 'Helvetica Neue', Helvetica, Arial, sans-serif;

/* r=3.0, n=3, f0=1.0 */
$scale-1: 0.6934rem;
$scale0: 1.0rem;
$scale1: 1.4422rem;
$scale2: 2.0801rem;
$scale3: 3.0rem;

$leading: 1.8;
$leading-sm: 1.5;
$leading-xs: 1.2;
$leading-lg: 2;

$character-width: 40ch;




// layout
$container-max-width: 1440px;


// breakpoints
// em based media queries are always based on the browser default font size (16px or set by user)
$breakpoint-1: 25em; // 400px
$breakpoint-2: 50em; // 800px
$breakpoint-3: 64em; // 1024px
$breakpoint-4: 90em; // 1440px


// spacing
$spacing: 1em;
$spacing-sm: 0.5em;
$spacing-xs: 0.25em;
$spacing-xxs: 0.125em;
$spacing-lg: 1.5em;
$spacing-xl: 2.5em;


// forms
$widget-font-size: $scale0;
$widget-height: 2em;
$widget-width: 15em;
$widget-width-sm: 10em; // 140px
$widget-width-xs: 5em; // 70px
$widget-box-shadow-size: 5px;


// etc
$border-radius: 4px;
$breadcrumb-separator: '\2192';


// icons
$icon-width: 1.5em;
$icon-width-sm: 1em;
$icon-width-lg: 2em;
$icon-width-xl: 4em;
$icon-width-xxl: 8em;


// gallery thumbs
$gallery-thumb-size: 180px;


