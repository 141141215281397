img {
    max-width: 100%;

    &.cover {
	object-fit: cover;
    }

    &.contain {
	object-fit: contain;
    }

    &.stretch {
	width: 100%;
    }
}

