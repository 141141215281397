#main-menu {
    background-color: $primary-menu-bg;
    transition: height 0.5s ease-in-out;
    margin: 0;

    @media (hover: hover) {
	margin: $spacing 0;
    }
}
#toggle-menu {
    fill: $white;
    cursor: pointer;

    @media (hover: hover) {
	display: none;
    }

    rect {
	transform-origin: center center;
	transition: all 0.25s ease-in-out;
    }

    &.active {
	rect { fill: $complementary-colour; }
	#bar1, #bar4 { opacity: 0; }
	#bar2 { transform: rotate(45deg); }
	#bar3 { transform: rotate(-45deg); }
    }
}
.dropdown {
    display: block;
    width: 100%;
    margin: $spacing;
    font-size: $scale0;

    @media (hover: hover) {
	width: auto;

	> .container {
	    padding: 0;
	}

	ul {
	    @include flexify($wrap: wrap);
	    margin-right: -$spacing;
	    margin-bottom: -$spacing;
	}
    }

    li {
	display: block;
	position: relative;
	transition-duration: 0.5s;
	border-bottom: 1px solid darken($primary-colour, 5%);

	@media (hover: hover) {
	    border: none;
	    margin-right: $spacing-xl;
	}

	&:hover {
	    cursor: pointer;
	    background-color: $primary-menu-hover-bg;

	    @media (hover: none) {
		background-color: transparent;
	    }
	}

	&#menu-search {
	    overflow: hidden;

	    form {
		margin-right: 0;

		input[type=text] {
		    width: 15em;
		    @include prefixer((
			transition: width 0.5s ease
		    ), webkit moz ms o);
		}

		&.hidden {
		    margin-right: $spacing;

		    input[type=text] {
			width: 0;
			padding: 0;
			overflow: hidden;
		    }
		}
	    }
	}

	> a {
	    color: $primary-menu-link-colour;
	    display: block;
	    padding: $spacing-sm;

	    &.active {
		color: $primary-menu-active-link-colour;

		.icon {
		    stroke: $link-colour;
		}
	    }

	    &:hover {
		color: $primary-menu-hover-colour;
		background-color: $primary-menu-hover-bg;

		@media (hover: none) {
		    background-color: transparent;
		}
	    }


	    .icon {
		stroke-width: 3;
		stroke: $white;
	    }
	}

	form {
	    display: inline-block;
	}
    }

    .sub-menu {
	color: $primary-sub-menu-link-colour;
	min-width: 16rem;
	transition: all 0.5s ease;
	left: 0;
	overflow: hidden;
	background-color: $primary-mobile-sub-menu-bg;
	margin-left: $spacing-lg;

	@media (hover: hover) {
	    background-color: $primary-sub-menu-bg;
	    visibility: hidden;
	    opacity: 0;
	    display: none;
	    position: absolute;
	    margin-left: 0;

	    a {
		color: $primary-sub-menu-link-colour;

		&.active {
		    color: $primary-sub-menu-active-colour;
		}

		&:hover {
		    background-color: $primary-sub-menu-hover-bg;
		}
	    }

	    .sub-menu {
		left: 15em;
		top: 0;
	    }
	}

	li {
	    display: block;
	    border-bottom: 1px solid $primary-sub-menu-hover-bg;
	    margin: 0;

	    &:last-child {
		border: none;
	    }
	}

    }

    li.has-submenu {

	> a {
	    position: relative;

	    &::after {
		vertical-align: middle;
		line-height: 1;
		content: "";
		display: inline-block;
		width: 0; 
		height: 0; 

		border: none;
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		border-left: 4px solid;
		position: absolute;
		top: 45%;
		right: 0;

		@media (hover: hover) {
		    position: static;
		    border: none;
		    border-left: 4px solid transparent;
		    border-right: 4px solid transparent;
		    border-top: 4px solid;
		    margin-left: 0.25em;
		}
	    }

	    &.triggered {
		&::after {
		    border: none;
		    border-left: 4px solid transparent;
		    border-right: 4px solid transparent;
		    border-top: 4px solid;
		}
	    }
	}

	&:hover {
	    > .sub-menu {
		visibility: visible;
		display: block;
		opacity: 1;
		z-index: 1000;
	    }
	}

	.has-submenu {
	    @media (hover: hover) {
		> a {
		    &::after {
			vertical-align: middle;
			line-height: 1;
			content: "";
			display: inline-block;
			width: 0; 
			height: 0; 
			border-top: 4px solid transparent;
			border-bottom: 4px solid transparent;
			border-left: 4px solid;
			margin-left: 0.25em;
		    }
		}
	    }
	}
    }
}

nav.sidebar {
    li {
	margin: $spacing-sm 0;
	padding: 0 $spacing-sm;
	border-bottom: 1px dashed $secondary-colour;

	&.active a {
	    color: $text-colour;
	}

	a.submenu-trigger {
	    &::after {
		vertical-align: middle;
		line-height: 1;
		content: "";
		display: inline-block;
		width: 0; 
		height: 0; 
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		border-left: 4px solid;
		margin-left: 0.25em;
	    }
	}
    }

    ul.sub-menu {
	overflow: hidden;
	//margin-left: $spacing-sm;
	font-size: $scale-1;
	transition: height 0.5s ease-in-out;

	&.off {
	    height: 0;
	}

	li {
	    &::before {
		content: "-";
		display: inline-block;
		margin-right: $spacing-sm;
	    }
	}
    }
}
