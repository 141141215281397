.carousel-outer {
    > a.prev, > a.next {
	color: $grey;
	font-size: $scale3;
	font-family: monospace;
	text-decoration: none;
    }
}


.carousel {
    overflow: hidden;
    position: relative;
    margin: $spacing 0;

    nav {
	@include flexify($align: center, $justify: space-between);
	position: absolute;

	a {
	    font-family: monospace;
	    font-size: $scale3;
	    color: $white;
	    text-decoration: none;

	    &.prev {
		margin-left: 1rem;
	    }

	    &.next {
		margin-right: 1rem;
	    }
	}

	&.overlay {
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}

	&.noverlay {
	    top: 0;
	    right: 0;
	    display: inline-block;
	}

	&.outside {
	    position: static;
	    @include flexify($align: center, $justify: center);

	    a {
		color: $grey;
	    }
	}
    }

    .slides {
	@include prefixer(( transition: transform .5s ease ), webkit moz ms o);
	@include flexify($align: flex-start);

	> * {
	    width: 100%;
	    flex-shrink: 0;
	}

	figure {
	    position: relative;

	    img {
		display: block;
		width: 100%;
	    }

	    figcaption {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: $spacing-sm;
		background-color: $caption-bg;
		color: $caption-colour;

	    }
	}

	&.caption-outside {
	    figure {
		figcaption {
		    position: static;
		}
	    }
	}
    }
}
