body.with-off-canvas {
    @include flexify();

    &.is-open {
	.off-canvas {
	    padding: $spacing;
	    width: 240px;
	}

	.on-canvas {
	    flex: 1;
	}
    }

    .off-canvas {
	background-color: $dark-grey;
	color: $light-grey;

	min-height: 100vh;
	flex-shrink: 0;
	width: 0;
	overflow: hidden;

	@include prefixer(( transition: all .5s ease ), webkit moz ms o);
    }

    .on-canvas {
	flex: 1;
	@include prefixer(( transition: all .5s .5s ease ), webkit moz ms o);
    }
}
